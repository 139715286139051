<template>
  <vx-card class="contained-example-container">
    <div class="header">
      <div class="row">
        <div>
          <h4>{{ title }}</h4>
        </div>
        <div>
          <div class="actions">
            <span @click="download">
              <vs-icon
                v-tooltip="lang.metrics.download[languageSelected]"
                icon="icon-download"
                icon-pack="feather"
                style="cursor: pointer"
              ></vs-icon>
            </span>
            <!-- <metric-dropdown
              :on-last-week="updateInterval"
              :on-last-month="updateInterval"
              :on-range-select="openRangeInterval"
            />-->
          </div>
          <!-- <DatepickerRangePopup ref="datepickerRangePopup" @save="saveRangeInterval" /> -->
        </div>
      </div>
      <div>
        <h6 class="description">{{ description }}</h6>
      </div>
    </div>

    <div
      class="p-6 pb-base vs-con-loading__container"
      :id="`div-with-loading-${report._id}`"
    >
      <div class="flex">
        <div
          v-for="(item, index) in messagesByPlatform"
          :key="index"
          :class="{ 'ml-4': index > 0 }"
        >
          <p class="mb-1 font-semibold">{{ PLATFORM_NAMES[item.platform] }}</p>
          <p class="text-3xl text-grey">{{ item.count }}</p>
        </div>
        <div
          v-if="showTotal"
          :class="{ 'ml-4': messagesByPlatform.length > 0 }"
        >
          <p class="mb-1 font-primary text-primary">Total</p>
          <p class="text-3xl text-grey">
            {{
              messagesByPlatform
                .map(element => element.count)
                .reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0)
            }}
          </p>
        </div>
      </div>
      <div :v-show="showHistogram">
        <vue-apex-charts
          v-if="lineAreaChartSpline.series.length > 0 && showHistogram"
          type="line"
          height="266"
          :options="lineAreaChartSpline.chartOptions"
          :series="lineAreaChartSpline.series"
        />
      </div>
    </div>
  </vx-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { mapState, mapActions } from 'vuex'
import { COLORS, PLATFORM_NAMES } from '@/constants/constants'
const Json2csvParser = require('json2csv').Parser
const moment = require('moment')

export default {
  name: 'HistogramByPlatformV1',
  props: {
    report: {
      type: Object,
      required: true
    },
    type: {
      type: Array
    },
    platforms: {
      type: Array
    },
    services: {
      type: Array,
      required: true
    },
    interval: Object,
    versions: {
      type: Array,
      required: true
    }
  },
  components: {
    VueApexCharts
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    title() {
      const found = this.report.name.find(element => {
        return element.lang === this.languageSelected
      })
      if (found) {
        return found.text
      }
      return 'N/A'
    },
    description() {
      const found = this.report.description.find(element => {
        return element.lang === this.languageSelected
      })
      if (found) {
        return found.text
      }
      return 'N/A'
    }
  },
  data() {
    return {
      Json2csvParser,
      PLATFORM_NAMES,
      // interval: {},
      showHistogram: true,
      messagesByPlatform: [],
      lineAreaChartSpline: {
        series: [],
        chartOptions: {
          chart: {
            toolbar: { show: false }
          },
          stroke: {
            curve: 'smooth',
            dashArray: [0, 0],
            width: [4, 4]
          },
          grid: {
            borderColor: '#e7e7e7'
          },
          legend: {
            horizontalAlign: 'center',
            showForSingleSeries: true,
            offsetY: 6,
            onItemHover: {
              highlightDataSeries: true
            }
          },
          colors: ['#F97794', COLORS.platform.whatsapp],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: ['#7367F0', COLORS.platform.whatsapp],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 0.85,
              opacityTo: 0.85,
              stops: [0, 100, 100, 100]
            }
          },
          markers: {
            size: 0,
            hover: {
              size: 5
            }
          },
          xaxis: {
            labels: {
              // rotate: -15,
              // rotateAlways: true,
              hideOverlappingLabels: true,
              style: {
                fontSize: '9px',
                cssClass: 'text-grey'
              }
            },
            axisTicks: {
              show: false
            },
            categories: [],
            axisBorder: {
              show: false
            }
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                cssClass: 'text-grey fill-current'
              },
              formatter: function (val) {
                return val > 999 ? (val / 1000).toFixed(1) + 'k' : val
              }
            }
          },
          tooltip: {
            x: { show: false }
          }
        }
      },
      showTotal: true
    }
  },
  watch: {
    services() {
      this.load(this.report)
    },
    interval() {
      this.load(this.report)
    },
    platforms() {
      this.load(this.report)
    },
    versions() {
      this.load(this.report)
    }
  },
  methods: {
    ...mapActions('metrics', ['getReportData']),
    download() {
      function saveData(blob, fileName) {
        var a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = fileName
        a.click()
        window.URL.revokeObjectURL(url)
      }

      let fields = ['Date']
      fields = fields.concat(
        this.lineAreaChartSpline.series.map(element => element.name)
      )
      if (this.showTotal) {
        fields = fields.concat(['Total'])
      }
      let data = []
      this.lineAreaChartSpline.chartOptions.xaxis.categories.forEach(
        (date, index) => {
          data.push({
            Date: date
          })
          let total = 0
          this.lineAreaChartSpline.series.forEach(element => {
            data[data.length - 1][element.name] = parseInt(element.data[index])
            total += data[data.length - 1][element.name]
          })
          if (this.showTotal) {
            data[data.length - 1]['Total'] = total
          }
        }
      )

      const json2csvParser = new Json2csvParser({ fields })
      const csv = json2csvParser.parse(data)

      let filename = `konecta_metric_${this.title}_`
      filename += new Date().toISOString()

      var BOM = '\uFEFF'
      var csvData = BOM + csv

      var blob = new Blob([csvData], {
        type: 'text/plain; encoding=utf-8'
      })

      saveData(blob, `${filename}.csv`)
    },
    getDates() {
      let listDate = []
      let startDate = this.interval.from.toISOString()
      let endDate = this.interval.to.toISOString()
      let dateMove = new Date(startDate)
      let strDate = startDate

      while (strDate < endDate) {
        strDate = dateMove.toISOString().slice(0, 10)
        listDate.push(new Date(strDate).toISOString())
        dateMove.setDate(dateMove.getDate() + 1)
      }

      return listDate.slice(0, -1)
    },
    async load(report) {
      let to = new Date(this.interval.to)
      let from = new Date(this.interval.from)

      if (
        to.getDate() == from.getDate() &&
        to.getMonth() == from.getMonth() &&
        to.getFullYear() == from.getFullYear()
      ) {
        this.showHistogram = false
      } else {
        this.showHistogram = true
      }
      if (!this.services || this.services.length === 0) {
        return
      }
      if (!this.versions || this.versions.length === 0) {
        return
      }
      if (!this.platforms || this.platforms.length === 0) {
        return
      }
      const toDateStr =
        this.interval && moment(this.interval.to).format('YYYY-MM-DD HH:mm:ss')
      const fromDateStr =
        this.interval &&
        moment(this.interval.from).format('YYYY-MM-DD HH:mm:ss')
      const data = {
        type: this.type,
        services: this.services,
        versions: this.versions,
        from: fromDateStr,
        to: toDateStr,
        platforms: this.platforms
      }
      this.$vs.loading({
        container: `#div-with-loading-${report._id}`,
        scale: 1.0
      })
      try {
        const result = await this.getReportData({
          reportId: report._id,
          payload: data
        })
        this.messagesByPlatform = result.data[0]
        // this.lineAreaChartSpline.chartOptions.xaxis.categories = this.getDates().map(
        //   element => `${element.split('T')[0]}`
        // )
        this.lineAreaChartSpline.chartOptions.xaxis.categories = []
        const series = result.data[0].map(element => {
          return {
            name: this.PLATFORM_NAMES[element.platform],
            data: []
          }
        })
        result.data[1].forEach(element => {
          series.forEach(serie => {
            if (element[serie.name.toLowerCase()]) {
              serie.data.push(element[serie.name.toLowerCase()])
            }
          })
          this.lineAreaChartSpline.chartOptions.xaxis.categories.push(
            `${element['day'].split('T')[0]}`
          )
        })
        this.lineAreaChartSpline.series = series
        this.lineAreaChartSpline = JSON.parse(
          JSON.stringify(this.lineAreaChartSpline)
        )
        this.$vs.loading.close(
          `#div-with-loading-${report._id} > .con-vs-loading`
        )
      } catch (ex) {
        if (ex.message !== 'cancelled') {
          this.$vs.loading.close(
            `#div-with-loading-${report._id} > .con-vs-loading`
          )
          const reportName =
            this.languageSelected === 'es'
              ? report.name[0].text
              : report.name[1].text
          this.$vs.notify({
            text:
              this.lang.metrics.reportMessages.text[this.languageSelected] +
              `"${reportName}"`,
            color: 'danger'
          })
          console.error(ex)
        }
      }
    }
  },
  mounted() {
    this.load(this.report)
    if (this.report._extra && this.report._extra.showTotal === false) {
      this.showTotal = false
    }
  }
}
</script>

<style lang="scss">
.header {
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
  }
  .description {
    margin-top: 0.5rem;
    color: #b8c2cc;
  }
}
</style>
